const list = [
  { code: 'af-ZA', language: 'Afrikaans', dialect: 'South Africa' },
  { code: 'ar-XA', language: 'Arabic' },
  { code: 'am-ET', language: 'Amharic', dialect: 'Ethiopia' },
  { code: 'bg-BG', language: 'Bulgarian', dialect: 'Bulgaria' },
  { code: 'bn-IN', language: 'Bengali', dialect: 'India' },
  { code: 'ca-ES', language: 'Catalan', dialect: 'Spain' },
  { code: 'cmn-CN', language: 'Chinese', dialect: 'China' },
  { code: 'cmn-TW', language: 'Chinese', dialect: 'Taiwan' },
  { code: 'cs-CZ', language: 'Czech', dialect: 'Czech Republic' },
  { code: 'da-DK', language: 'Danish', dialect: 'Denmark' },
  { code: 'de-DE', language: 'German', dialect: 'Germany' },
  { code: 'el-GR', language: 'Greek', dialect: 'Greece' },
  { code: 'en-AU', language: 'English', dialect: 'Australia' },
  { code: 'en-GB', language: 'English', dialect: 'UK' },
  { code: 'en-IN', language: 'English', dialect: 'India' },
  { code: 'en-US', language: 'English', dialect: 'US' },
  { code: 'es-ES', language: 'Spanish', dialect: 'Spain' },
  { code: 'es-US', language: 'Spanish', dialect: 'US' },
  { code: 'et-EE', language: 'Estonian', dialect: 'Estonia' },
  { code: 'eu-ES', language: 'Basque', dialect: 'Spain' },
  { code: 'fi-FI', language: 'Finnish', dialect: 'Finland' },
  { code: 'fil-PH', language: 'Filipino', dialect: 'Philippines' },
  { code: 'fr-CA', language: 'French', dialect: 'Canada' },
  { code: 'fr-FR', language: 'French', dialect: 'France' },
  { code: 'gl-ES', language: 'Galician', dialect: 'Spain' },
  { code: 'gu-IN', language: 'Gujarati', dialect: 'India' },
  { code: 'he-IL', language: 'Hebrew', dialect: 'Israel' },
  { code: 'hi-IN', language: 'Hindi', dialect: 'India' },
  { code: 'hu-HU', language: 'Hungarian', dialect: 'Hungary' },
  { code: 'id-ID', language: 'Indonesian', dialect: 'Indonesia' },
  { code: 'it-IT', language: 'Italian', dialect: 'Italy' },
  { code: 'is-IS', language: 'Icelandic', dialect: 'Iceland' },
  { code: 'ja-JP', language: 'Japanese', dialect: 'Japan' },
  { code: 'kn-IN', language: 'Kannada', dialect: 'India' },
  { code: 'ko-KR', language: 'Korean', dialect: 'South Korea' },
  { code: 'lt-LT', language: 'Lithuanian', dialect: 'Lithuania' },
  { code: 'lv-LV', language: 'Latvian', dialect: 'Latvia' },
  { code: 'ml-IN', language: 'Malayalam', dialect: 'India' },
  { code: 'mr-IN', language: 'Marathi', dialect: 'India' },
  { code: 'ms-MY', language: 'Malay', dialect: 'Malaysia' },
  { code: 'nb-NO', language: 'Norwegian', dialect: 'Norway' },
  { code: 'nl-BE', language: 'Dutch', dialect: 'Belgium' },
  { code: 'nl-NL', language: 'Dutch', dialect: 'Netherlands' },
  { code: 'pa-IN', language: 'Punjabi', dialect: 'India' },
  { code: 'pl-PL', language: 'Polish', dialect: 'Poland' },
  { code: 'pt-BR', language: 'Portuguese', dialect: 'Brazil' },
  { code: 'pt-PT', language: 'Portuguese', dialect: 'Portugal' },
  { code: 'ro-RO', language: 'Romanian', dialect: 'Romania' },
  { code: 'ru-RU', language: 'Russian', dialect: 'Russia' },
  { code: 'sk-SK', language: 'Slovak', dialect: 'Slovakia' },
  { code: 'sr-RS', language: 'Serbian', dialect: 'Cyrillic' },
  { code: 'sv-SE', language: 'Swedish', dialect: 'Sweden' },
  { code: 'ta-IN', language: 'Tamil', dialect: 'India' },
  { code: 'te-IN', language: 'Telugu', dialect: 'India' },
  { code: 'th-TH', language: 'Thai', dialect: 'Thailand' },
  { code: 'tr-TR', language: 'Turkish', dialect: 'Turkey' },
  { code: 'uk-UA', language: 'Ukrainian', dialect: 'Ukraine' },
  { code: 'ur-IN', language: 'Urdu', dialect: 'India' },
  { code: 'vi-VN', language: 'Vietnamese', dialect: 'Vietnam' },
  { code: 'yue-HK', language: 'Chinese', dialect: 'Hong Kong' },
];

const map = list.reduce((map, voice) => ({ ...map, [voice.code]: voice }), {});

const toLanguage = ({ languageCode }) => map[languageCode]?.language;
const toDialect = ({ languageCode }) => map[languageCode]?.dialect;

const parseName = (voice) => voice.name.split('-')[3];
const parseQuality = (voice) => voice.name.split('-')[2];

const parseVoice = (voice) => ({
  dialect: toDialect(voice),
  gender: voice.gender,
  language: toLanguage(voice),
  id: voice.name,
  name: parseName(voice),
  quality: parseQuality(voice),
});

const voices = { parseVoice, toLanguage, toDialect };

export default voices;
export { list, parseVoice, toLanguage, toDialect };
